import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Filter from "./filter"
import Article from "./article"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.section``

const Empty = styled.div.attrs({ className: `styled-h4` })`
  padding-top: 10vh;
  padding-bottom: 10vh;
`

const Resources = ({ data: { edges }, withFilter = true, title }) => {
  const [activeType, setActiveType] = useState(``)

  const filteredEdges = useMemo(() => {
    if (activeType) {
      return edges.filter(({ node }) =>
        node.type.find((n) => n.name === activeType)
      )
    }
    return edges
  }, [activeType, edges])

  return (
    <Container>
      {edges && edges.length > 0 && withFilter && (
        <Grid noBottomPadding>
          {title && (
            <Row>
              <Cell
                size={5}
                mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 20 } }}
              >
                <h2 className="styled-h3 styled">{title}</h2>
              </Cell>
            </Row>
          )}
          <Row>
            <Cell
              size={5}
              mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 20 } }}
            >
              <Filter
                data={edges}
                activeType={activeType}
                onChange={setActiveType}
              />
            </Cell>
          </Row>
        </Grid>
      )}

      {filteredEdges && filteredEdges.length > 0 ? (
        <Grid>
          {title && !withFilter && (
            <Row>
              <Cell
                size={5}
                mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 20 } }}
              >
                <h2 className="styled-h3 styled">{title}</h2>
              </Cell>
            </Row>
          )}
          <Row pushY={1} pullY={2} mq={{ xsmallDown: { pullY: 6 } }}>
            {filteredEdges.map((resource, i) => (
              <Cell
                key={i}
                size={7}
                pushX={i % 3 !== 0 && 1}
                pushY={i >= 3 && 2}
                mq={{
                  mediumDown: {
                    size: 11,
                    pushX: i % 2 !== 0 && 1,
                    pushY: i >= 2 && 2,
                  },
                  xsmallDown: {
                    size: 23,
                    pushX: i % 1 !== 0 && 1,
                    pushY: i >= 1 && 4,
                  },
                }}
              >
                <Article data={resource} />
              </Cell>
            ))}
          </Row>
        </Grid>
      ) : (
        <Grid>
          <StoreConsumer>
            {({ translations }) => (
              <Empty
                dangerouslySetInnerHTML={{
                  __html: translations[`resources_empty`],
                }}
              />
            )}
          </StoreConsumer>
        </Grid>
      )}
    </Container>
  )
}

Resources.propTypes = {
  data: PropTypes.object.isRequired,
  withFilter: PropTypes.bool,
  title: PropTypes.string,
}

export default Resources
