import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { orderBy } from "lodash"

import { StoreConsumer } from "../../store"

const Select = styled.select`
  width: 100%;
  height: 2.5em;
  padding-right: 1em;
  display: block;
  font-size: 1.125em;
  border-bottom: 1px solid ${(props) => props.theme.colorGreyDark};
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: top 1.1em right;
  background-size: 0.6em;
  background-image: url("data:image/svg+xml,${(props) =>
    encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
      <path d="M5 6l-5-6h10z" fill="${props.theme.colorBlack}"/>
    </svg>
  `)}");

  ${(props) =>
    props.emptyValue &&
    css`
      color: ${(props) => props.theme.colorPurpleLight};
    `}
`

const Filter = ({ data, activeType, onChange }) => {
  const types = useMemo(() => {
    let types = []

    data.forEach(({ node }) => {
      node.type.forEach(({ name }) => {
        if (!types.includes(name)) types.push(name)
      })
    })

    if (types.length) {
      types = orderBy(types, [], [`asc`])
    }

    return types
  }, [data])

  if (!types.length) return null

  return (
    <Select
      emptyValue={!activeType}
      onChange={(e) => onChange(e.currentTarget.value)}
    >
      <StoreConsumer>
        {({ translations }) => (
          <option
            value=""
            dangerouslySetInnerHTML={{
              __html:
                translations[
                  activeType ? `resources_filter_all` : `resources_filter_by`
                ],
            }}
          />
        )}
      </StoreConsumer>

      {types.map((type, i) => (
        <option
          key={i}
          value={type}
          selected={activeType === type ? `selected` : undefined}
        >
          {type}
        </option>
      ))}
    </Select>
  )
}

Filter.propTypes = {
  data: PropTypes.array.isRequired,
  activeType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Filter
