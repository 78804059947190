import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import truncateHtml from "truncate-html"

import Link from "../link"
import { StoreConsumer } from "../../store"

const Container = styled.article``

const Image = styled.figure`
  margin-bottom: 3em;

  @media ${(props) => props.theme.largeDown} {
    margin-bottom: 2em;
  }

  @media ${(props) => props.theme.smallDown} {
    margin-bottom: 1em;
  }

  a {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    display: block;
    position: relative;
    background-color: ${(props) => props.theme.colorGreyLight};

    &:hover {
      opacity: 0.9;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Title = styled.h3.attrs({ className: `styled-p-leading` })`
  a:hover {
    color: ${(props) => props.theme.colorPurpleLight};
  }
`

const Excerpt = styled.p.attrs({ className: `styled-p-leading` })`
  color: ${(props) => props.theme.colorPurpleLight};
`

const Cta = styled.p.attrs({ className: `styled-p-leading` })`
  margin-top: 1em;
`

const Meta = styled.footer.attrs({ className: `styled-p` })`
  margin-top: 1em;
  display: flex;
  color: ${(props) => props.theme.colorPurpleLight};

  time {
    white-space: nowrap;
  }

  ul {
    position: relative;
    padding-left: 1.4em;

    &::before {
      content: "";
      width: 0.4em;
      height: 0.4em;
      position: absolute;
      top: 0.45em;
      left: 0.5em;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0.5;
    }

    li {
      display: inline;

      &:not(:last-child) {
        &::after {
          content: ", ";
        }
      }
    }
  }
`

const Article = ({
  data: {
    node: {
      title,
      date,
      type,
      acf: { url, fileUrl, ctaText, excerptText, excerptImage },
    },
  },
}) => {
  const theUrl = url
    ? url
    : fileUrl && fileUrl.localFile
    ? fileUrl.localFile.url
    : null

  const excerpt = useMemo(
    () => truncateHtml(excerptText, { length: 9999, stripTags: true }),
    [excerptText]
  )

  return (
    <Container>
      {excerptImage && excerptImage.localFile && (
        <Image>
          <Link to={theUrl} target="_blank">
            <Img
              fluid={excerptImage.localFile.childImageSharp.fluid}
              alt={title}
              objectPosition="center center"
              objectFit="cover"
            />
          </Link>
        </Image>
      )}

      <Title>
        <Link
          to={theUrl}
          target="_blank"
          dangerouslySetInnerHTML={{ __html: title }}
        ></Link>
      </Title>

      {excerptText && <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />}

      <Cta>
        <StoreConsumer>
          {({ translations }) => (
            <Link
              to={theUrl}
              target="_blank"
              className="styled-a"
              dangerouslySetInnerHTML={{
                __html: ctaText || translations[`find_out_more`],
              }}
            />
          )}
        </StoreConsumer>
      </Cta>

      <Meta>
        <time dateTime={date}>{date}</time>

        {type && type.length > 0 && (
          <ul>
            {type.map((type, i) => (
              <li key={i}>{type.name}</li>
            ))}
          </ul>
        )}
      </Meta>
    </Container>
  )
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Article
